<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card class="pa-5" tile>
      <v-card-title class="pl-0 pr-0 mb-7">
        <h1 class="font-weight-black text-h6">PLAN DE INTERNET MÓVIL</h1>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          <icon-close class="icon--close"></icon-close>
        </v-btn>
      </v-card-title>

      <div>
        <v-autocomplete
          class="rounded-0"
          v-model="purchase.idClient"
          height="50"
          prepend-inner-icon="mdi-magnify"
          placeholder="Escribe el nombre o no. de teléfono del cliente"
          outlined
          :items="items"
          item-text="name"
          :filter="filter"
          return-object
          dense
        >
          <template v-slot:item="data">
            <template class="blue">
              <div class="d-flex justify-space-between" style="width: 100%">
                <div class="text-uppercase">{{ data.item.name }}</div>
                <div>{{ data.item.movilNumber }}</div>
              </div>
            </template>
          </template>
        </v-autocomplete>
      </div>

      <!-- RESULTADOS DE LA BUSQUEDA -->
      <div class="d-flex justify-space-between" v-if="purchase.idClient !== ''">
        <div>
          <p class="text-subtitle-2 font-weight-regular mb-2">Cliente</p>
          <p class="text-uppercase font-weight-black">
            {{ purchase.idClient.name }}
          </p>
        </div>
        <div>
          <p class="text-subtitle-2 font-weight-regular mb-2">
            No. de teléfono
          </p>
          <p class="font-weight-black">{{ purchase.idClient.movilNumber }}</p>
        </div>
      </div>

      <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
        <div>
          <div>
            <p class="text-subtitle-2 font-weight-regular mb-2">Descripción</p>
            <v-text-field
              v-model="purchase.description"
              class="rounded-0"
              dense
              height="50"
              placeholder="Escriba una descripción breve sobre el plan"
              outlined
              :rules="descriptionRules"
            ></v-text-field>
          </div>
          <div>
            <p class="text-subtitle-2 font-weight-regular mb-2">Días</p>
            <v-text-field
              suffix="día(s)"
              v-model.number="purchase.days"
              class="rounded-0"
              dense
              height="50"
              placeholder="Escriba el número de días para el plan"
              outlined
              type="number"
              :rules="daysRules"
            ></v-text-field>
          </div>
          <div>
            <p class="text-subtitle-2 font-weight-regular mb-2">Costo</p>
            <v-text-field
              prefix="$"
              v-model.number="purchase.price"
              class="rounded-0"
              dense
              height="50"
              placeholder="Escriba el costo del plan"
              outlined
              type="number"
              :rules="daysRules"
            ></v-text-field>
          </div>
          <div>
            <p class="text-subtitle-2 font-weight-regular mb-2">
              Estado de pago
            </p>
            <div class="d-flex justify-space-between">
              <v-btn
                x-large
                style="width: 48%"
                depressed
                :outlined="purchase.status !== 'PAGADA'"
                :dark="purchase.status === 'PAGADA'"
                :color="
                  purchase.status === 'PAGADA' ? 'success' : 'grey lighten-1'
                "
                tile
                @click="changeStatus('PAGADA')"
                >PAGADA</v-btn
              >
              <v-btn
                x-large
                style="width: 48%"
                depressed
                :outlined="purchase.status === 'PAGADA'"
                :dark="purchase.status !== 'PAGADA'"
                :color="
                  purchase.status !== 'PAGADA' ? '#E86E0C' : 'grey lighten-1'
                "
                tile
                @click="changeStatus('PAGO PENDIENTE')"
                >PENDIENTE</v-btn
              >
            </div>
          </div>
        </div>
      </v-form>
      <div class="mt-5 d-flex justify-space-between align-end">
        <div class="image--container">
          <v-img
            src="@/assets/icons/icon-internet-movil-aqua.svg"
            width="80"
          ></v-img>
        </div>
        <v-btn
          :disabled="purchase.idClient === ''"
          depressed
          color="primary"
          @click="pay()"
          >PONER PLAN</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// Domain
import Client from "../../domain/Clients";

export default {
  name: "InternetMovilPurchase",
  mounted() {
    this.getClients();
  },
  components: {
    IconClose: () => import("../icons/IconClose"),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    purchase: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    items: [],
    valid: true,
    descriptionRules: [(v) => !!v || "El campo es requerido"],
    daysRules: [
      (v) => !!v || "El campo es requerido",
      (v) => v >= 1 || "El valor mínimo es uno",
    ],
  }),
  methods: {
    changeStatus(status) {
      this.purchase.status = status;
    },
    async getClients() {
      try {
        this.items = await Client.getAllForPurchase();
      } catch (error) {
        console.warn(error);
      }
    },
    filter(item, queryText /*, itemText*/) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.movilNumber.toString().includes(queryText.toLowerCase())
      );
    },
    pay() {
      if (!this.$refs.form.validate()) return false;
      this.$emit("pay", this.purchase);
    },
    async close() {
      await this.$refs.form.resetValidation();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.search--container {
  border: 1px solid red;
  padding-left: 1rem;
  padding-right: 1rem;
}

.search--input {
  width: 70% !important;
}

.icon--close {
  width: 1.3rem;
}

.icon--close:hover {
  cursor: pointer;
}

.image--container {
  width: 50%;
}
</style>